import env_client from "./env/env_clientside";
import type { StrokeType } from "./server/entities/pool/PoolIntervalSegmentEntity";
import type { Reaction } from "./server/reaction/reaction_repository";
import { DEFAULT_SPLIT_DISTANCE_IN_METERS } from "./track/track_const";
import type { MapLayerType } from "./ui_types";

export const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.swimerize.production";
export const APPLE_APP_STORE_URL = "https://apps.apple.com/us/app/swimerize/id6502660300?platform=iphone";

export const FEED_PAGE_SIZE = env_client.is_dev() ? 5 : 20;
export const FOLLOW_CANDIDATES_PAGE_SIZE = 20;
export const ACTIVITIES_PAGE_SIZE = env_client.is_dev() ? 3 : 20;;
export const COMMENTS_PAGE_SIZE = env_client.is_dev() ? 3 : 20;
export const DASHBOARD_INTERVALS_PAGE_SIZE = 20;
export const TRACK_VIEW_MATCHING_ACTIVITIES_PAGE_SIZE = env_client.is_dev() ? 3 : 10;

export const DEFAULT_TRACK_VIEW_TYPE = {view_type: 2, stretch_length: DEFAULT_SPLIT_DISTANCE_IN_METERS, selected_menu_item_idx: 1};
export const TRACK_ANIMATION_SPEED_INITIAL = 100;
export const TRACK_ANIMATION_SPEED_FACTORS = [1, 50, 100, 150];
export const POOL_ANIMATION_SPEED_INITIAL = 10;
export const POOL_ANIMATION_SPEED_FACTORS = [1, 5, 10, 25];

export const MAX_PHOTO_PIXEL_WIDTH = 1024;
export const MAX_PHOTO_THUMBNAIL_PIXEL_WIDTH = 256;
export const MAX_PICTURE_COUNT_UPLOAD = 20;

export const GROUP_NAME_MAX_LENGTH = 100;
export const GROUP_DESCRIPTION_MAX_LENGTH = 500;

export const PROFILE_NAME_MAX_LENGTH = 100;

export const HRZ_ZONE_1_COLOR = "bg-fuchsia-200";
export const HRZ_ZONE_2_COLOR = "bg-fuchsia-400";
export const HRZ_ZONE_3_COLOR = "bg-fuchsia-600";
export const HRZ_ZONE_4_COLOR = "bg-fuchsia-800";
export const HRZ_ZONE_5_COLOR = "bg-fuchsia-950";

export const HRZ_ZONE_1_COLOR_RGB = "#F5D0FE"; // bg-fuchsia-200
export const HRZ_ZONE_2_COLOR_RGB = "#E879F9"; // bg-fuchsia-400
export const HRZ_ZONE_3_COLOR_RGB = "#C026D3"; // bg-fuchsia-600
export const HRZ_ZONE_4_COLOR_RGB = "#86198F"; // bg-fuchsia-800
export const HRZ_ZONE_5_COLOR_RGB = "#4A044E"; // bg-fuchsia-950

export const HR_AVG_COLOR_RGB = "#939598";

export const PRIMARY_BASE_HSL_COLOR = "#C026D3"; //"hsla(293, 69%, 49%, 0.1)";

export const TRACK_PATH_CORRUPT_COLOR = "#939598";

export const COLORS = [
                        "hsl(293, 69%, 49%)", // fuchsia
                        "hsl(55, 100%, 50%)", // yellow
                        "hsl(180, 100%, 50%)", // aqua
                        "hsl(40, 100%, 50%)", // orange
                        "hsl(80, 100%, 50%)", // light green
                        "hsl(0, 100%, 50%)", // red
                        "hsl(275, 100%, 50%)", // purple
                        "hsl(200, 100%, 50%)", // light-blue
                        "hsl(100, 100%, 50%)", // green
                        "hsl(230, 100%, 50%)", // blue
                        "hsl(0, 0%, 50%)", // grey
                     ];

export const DEFAULT_PROFILE_IMAGE_PATH = '/images/image-default-avatar.svg';

/*
 * Emoji Source: https://github.com/svgmoji/svgmoji (Noto SVG)
 */
export const REACTIONS: {type: Reaction, name: string, src: string, active: boolean}[] = [
   {
       type: 1,
       name: 'Heart',
       src: '/reactions/2764.svg',
       active: true
   },
   {
      type: 2,
      name: 'Happy',
      src: '/reactions/1F600.svg',
      active: false
   },
   {
       type: 10,
       name: 'Like',
       src: '/reactions/1F44D.svg',
       active: true
   },
   {
      type: 3,
      name: 'Laugh',
      src: '/reactions/1F923.svg',
      active: true
    },
   {
      type: 4,
      name: 'Amazed',
      src: '/reactions/1F632.svg',
      active: false
    },
    {
      type: 5,
      name: 'Mind = Blown',
      src: '/reactions/1F92F.svg',
      active: true
   },
   {
       type: 6,
       name: 'Cold',
       src: '/reactions/1F976.svg',
       active: true
   },
   {
       type: 7,
       name: 'Hot',
       src: '/reactions/1F975.svg',
       active: true
   },
   {
       type: 8,
       name: 'Fire',
       src: '/reactions/1F525.svg',
       active: true
   },
   {
       type: 9,
       name: 'Strong',
       src: '/reactions/1F4AA.svg',
       active: true
   },
];

export const DEFAULT_MAP_STYLE: MapLayerType = 'ocean';

export const STROKE_TYPE_UI_LOOKUP: Record<StrokeType, {rank: number, label: string}> = {
    'butterfly': {rank: 4, label: 'Butterfly'}, 
    'backstroke': {rank: 2, label: 'Backstroke'},
    'breaststroke': {rank: 3, label: 'Breaststroke'}, 
    'freestyle': {rank: 1, label: 'Freestyle'}, 
    'im': {rank: 5, label: 'IM'}, 
    'mixed': {rank: 6, label: 'Mixed'}, 
    'drill': {rank: 7, label: 'Drill'}, 
    'idle': {rank: 8, label: 'Rest'},
};
